import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from '../../styles/Home.module.css'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Button = dynamic(() => import('@/components/Common/WhiteButton'))

const OldNew = () => {
  const size = '50px'

  return (
    <div style={{ paddingBlock: 80 }}>
      <Container>
        <div className={styles.newBadgeSection}>
          <h2 style={{ fontSize: 45, margin: 0 }}>
            Web<span style={{ fontWeight: 700, fontFamily: 'EnzoBold' }}>HR</span> <span style={{ fontWeight: 700, fontFamily: 'EnzoBold', color: '#2708A0' }}>Modules</span>
          </h2>
          <p style={{ color: '#898989' }}>Step into the future with hands-free work environment.</p>
        </div>
        <Row style={{ marginBottom: 20, marginTop: 20 }}>
          <Col md={3} sm={6} className={styles.newModuleHover}>
            <Link style={{ textDecoration: 'none', color: '#121212' }} href="/modules/scheduler">
              <div className={styles.newModuleStyles}>
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/scheduler.svg" style={{ marginBottom: 30 }} />
                <h4>Scheduler</h4>
                <p>The scheduler calendar offers a comprehensive view of staff availability, work status, leaves, and more.</p>
              </div>
            </Link>
          </Col>
          <Col md={3} sm={6} className={styles.newModuleHover}>
            <Link style={{ textDecoration: 'none', color: '#121212' }} href="/modules/time-and-attendance">
              <div className={styles.newModuleStyles}>
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/TimeAttendenceIcon.svg" style={{ marginBottom: 30 }} />
                <h4>Time & Attendance</h4>
                <p>The Easiest, Most Flexible, and Most Advanced and Easiest Time and Attendance Solution!</p>
              </div>
            </Link>
          </Col>
          <Col md={3} sm={6} className={styles.newModuleHover}>
            <Link style={{ textDecoration: 'none', color: '#121212' }} href="/modules/onboarding">
              <div className={styles.newModuleStyles}>
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/onBooard_icon.svg" style={{ marginBottom: 30 }} />
                <h4>On Boarding/Off Boarding</h4>
                <p>Intuitive On/Off boarding Solution that Covers all Your New Hires and old employee Requirements</p>
              </div>
            </Link>
          </Col>
          <Col md={3} sm={6} className={styles.newModuleHover}>
            <Link style={{ textDecoration: 'none', color: '#121212' }} href="/modules/core-hr">
              <div className={styles.newModuleStyles}>
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/document_icon.svg" style={{ marginBottom: 30 }} />
                <h4>Documents</h4>
                <p>WebHR can assure that your Employee Documents are always efficiently manageable and updated.</p>
              </div>
            </Link>
          </Col>
        </Row>

        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginBlock: 10 }}>
          <Button target blue text={'See All Modules'} link={'/modules'} />
        </div>
      </Container>
    </div>
  )
}

export default React.memo(OldNew)
